import { module } from 'modujs';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                'concerts': 'toggleConcerts'
            }
        }
    }

    toggleConcerts() {
        if (html.classList.contains('has-nav-open')) {
            html.classList.remove('has-concerts-open')
        } else {
            html.classList.add('has-concerts-open')
        }
    }
}