import { module } from 'modujs';
import { Curtains, Plane } from 'curtainsjs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        const curtains = new Curtains({
            container: this.el,
            watchScroll: false,
            pixelRatio: Math.min(1.2, window.devicePixelRatio)
        });

        const planeElement = document.querySelector(".plane");

        const vs = `#ifdef GL_ES
        precision mediump float;
        #endif
  
        // those are the mandatory attributes that the lib sets
        attribute vec3 aVertexPosition;
        attribute vec2 aTextureCoord;
  
        // those are mandatory uniforms that the lib sets and that contain our model view and projection matrix
        uniform mat4 uMVMatrix;
        uniform mat4 uPMatrix;
  
        // our texture matrix uniform (this is the lib default name, but it could be changed)
        uniform mat4 uTextureMatrix0;
  
        // if you want to pass your vertex and texture coords to the fragment shader
        varying vec3 vVertexPosition;
        varying vec2 vTextureCoord;
  
        void main() {
          vec3 vertexPosition = aVertexPosition;
  
          gl_Position = uPMatrix * uMVMatrix * vec4(vertexPosition, 1.0);
  
          // set the varyings
          // thanks to the texture matrix we will be able to calculate accurate texture coords
          // so that our texture will always fit our plane without being distorted
          vTextureCoord = (uTextureMatrix0 * vec4(aTextureCoord, 0.0, 1.0)).xy;
          vVertexPosition = vertexPosition;
        }`;

        const fs = `#ifdef GL_ES
        precision mediump float;
        #endif
  
        // get our varyings
        varying vec3 vVertexPosition;
        varying vec2 vTextureCoord;
  
        // the uniform we declared inside our javascript
        uniform float uTime;
  
        // our texture sampler (default name, to use a different name please refer to the documentation)
        uniform sampler2D uSampler0;
  
        void main() {
          // get our texture coords
          vec2 textureCoord = vTextureCoord;
  
          // displace our pixels along both axis based on our time uniform and texture UVs
          // this will create a kind of water surface effect
          // try to comment a line or change the constants to see how it changes the effect
          // reminder : textures coords are ranging from 0.0 to 1.0 on both axis
          const float PI = 2.141592;
  
          textureCoord.y += (
            sin(textureCoord.y * 10.0 + ((uTime * (PI / 2.023)) * 0.023))
            + sin(textureCoord.x * 10.0 + ((uTime * (PI / 3.1254)) * 0.037))
            ) * 0.0125;
  
          gl_FragColor = texture2D(uSampler0, textureCoord);
        }`;

        const params = {
            vertexShader: vs,
            fragmentShader: fs,
            uniforms: {
                time: {
                    name: "uTime",
                    type: "1f",
                    value: 0,
                }
            }
        };

        const plane = new Plane(curtains, planeElement, params);
        const planeBoundingRect = plane.getBoundingRect();

        plane.width = planeBoundingRect.width / curtains.pixelRatio;
        plane.height = planeBoundingRect.height / curtains.pixelRatio;

        plane.onRender(() => {
            plane.uniforms.time.value++;
        });
    }
}

