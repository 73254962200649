import { module } from 'modujs';
import gsap from 'gsap/all';

export default class extends module {
    constructor(m) {
        super(m);

        this.car = this.$('car')[0];
        this.palm = this.$('palm')[0];
        this.sticker = this.$('sticker')[0];
    }

    init() {
        this.carTl = gsap.timeline({ repeatDelay: 0, repeat: -1 });
        this.carTl.set(this.car, { xPercent: 120 })
        this.carTl.to(this.car, { xPercent: -50, duration: 1.5, ease: "elastic.out(0.5 , 0.3)" }, "+=1")
        this.carTl.to(this.palm, { opacity: 1, duration: 0.5 }, "<")
        this.carTl.to(this.palm, { skewY: 15, repeat: 3, yoyo: true, ease: "linear", duration: 1 }, "-=1.5")
        this.carTl.to(this.sticker, { autoAlpha: 0, duration: 0.25, repeat: 3, yoyo: true, ease: "linear" }, "<");
        this.carTl.to(this.car, { xPercent: -220, duration: 0.5, ease: "power4.out" }, "-=0.75")
        this.carTl.to(this.palm, { opacity: 0, duration: 0.5 }, "<")
    }

    destroy() {
        this.carTl.kill()
    }
}